/*
    Custom
 */
//= ../../node_modules/slick-carousel/slick/slick.min.js
//= ../../node_modules/wowjs/dist/wow.js
//= lib/pace.js
//= lib/jquery.event.move.js
//= lib/jquery.twentytwenty.js
//= lib/jquery.fancybox.js
//= lib/responsivevoice.min.js
//= lib/bvi-init-panel.min.js
//= lib/bvi.min.js
//= lib/js.cookie.min.js
//= partials/script.js
//= partials/maps.js
//= partials/sliceSlider.js